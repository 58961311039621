import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";

import Icon from "../../common/components/Icon";
import CallBackForm from "./CallBackForm";
import Modal from "../../modal/components/Modal";
import * as matomo from "../../analytics/utils/matomo";
import Obfuscate from "../../common/components/Obfuscate";

const ContactBar = () => {
  const intl = useIntl();
  const [contactFormVisible, setContactFormVisible] = useState(false);
  const toggleContactForm = () => setContactFormVisible(!contactFormVisible);

  const clickEventTracker = useCallback((action) => {
    return () => {
      matomo.trackEvent("Contact Bar Click", action, document.location.href);
    };
  }, []);
  return (
    <div className="contact-bar">
      <Modal
        visible={contactFormVisible}
        onClose={() => setContactFormVisible(false)}
        disableOkButton
      >
        <CallBackForm visible onHide={() => setContactFormVisible(false)} />
      </Modal>
      <Obfuscate
        className="contact-bar__item"
        onClick={clickEventTracker("call_us_button_click")}
        href="tel:+41 56 484 15 00"
        aria-label={intl.formatMessage({
          id: "call_us",
          defaultMessage: "Anrufen",
        })}
      >
        <div>
          <span>
            {intl.formatMessage({
              id: "call_us",
              defaultMessage: "Anrufen",
            })}{" "}
            <Obfuscate element="span">+41 56 484 15 00</Obfuscate>
          </span>
        </div>
        <Icon icon="phone" />
      </Obfuscate>
      <Obfuscate
        className="contact-bar__item"
        href="mailto:info@publicare.ch"
        onClick={clickEventTracker("email_button_click")}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={intl.formatMessage({
          id: "email_us",
          defaultMessage: "E-Mail schreiben",
        })}
      >
        <div>
          <span>
            {intl.formatMessage({
              id: "email_us",
              defaultMessage: "E-Mail schreiben",
            })}
          </span>
        </div>
        <Icon icon="envelope" fill="#FFFFFF" />
      </Obfuscate>
      <button
        type="button"
        className="no-button contact-bar__item align-items-center color-white"
        onClick={() => {
          if (!contactFormVisible) clickEventTracker("callback_button_click")();
          toggleContactForm();
        }}
        aria-label={intl.formatMessage({
          id: "callback_us",
          defaultMessage: "Rückruftermin",
        })}
      >
        <div>
          <span>
            {intl.formatMessage({
              id: "callback_us",
              defaultMessage: "Rückruftermin",
            })}
          </span>
        </div>
        <Icon icon="calendar-add-1-white" />
      </button>
    </div>
  );
};
export default ContactBar;
