import ContactBar from "./ContactBar";
import Footer from "./Footer";
import Header from "./Header";
import ContentPagesNavigation from "../../navigation/components/ContentPagesNavigation";
import Icon from "../../common/components/Icon";
import LayoutRenderer from "../../getcockpit/components/LayoutRenderer";
import findCurrentPageRouteTree from "../utils/findCurrentPageRouteTree";
import LocalizedHead from "modules/navigation/components/LocalizedHead";

const DefaultPageLayout = ({ currentPage, loading, menuTree }: any) => {
  const currentPageNavigation = findCurrentPageRouteTree(
    menuTree || [],
    currentPage?._r,
  );
  const { data, ...restPageData } = currentPage || {};
  return (
    <>
      <Header />
      <LocalizedHead
        fallbackTitle={currentPage?.title}
        routes={currentPage?._routes}
        {...currentPage}
      />
      <div className="container pt-3 d-md-flex">
        <ContactBar />
        <ContentPagesNavigation currentPageNavigation={currentPageNavigation} />
        <div className="cms">
          {loading ? <Icon className="loader-wrap" icon="loading" /> : ""}
          <LayoutRenderer
            key={currentPage?._id || currentPage?.id}
            {...data}
            {...restPageData}
            currentRoute={currentPage?._r}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DefaultPageLayout;
