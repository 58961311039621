const findCurrentPageRouteTree = (extractedRoutes, targetRoute) => {
  let foundRoute = null;

  const traverse = (routes, parentPath = [], parentIsActive = false) => {
    for (const route of routes) {
      const currentPath = [...parentPath, route];
      const isActive = parentIsActive || route.route === targetRoute;

      if (route.children && route.children.length > 0) {
        traverse(route.children, currentPath, isActive);
      }

      if (route.route === targetRoute) {
        // find the root route for cases when there is multiple paths to a page eg. dienstleistungen/download-center
        const rootRoute = parentPath.find(
          (pathRoute) => pathRoute.children.length,
        );

        if (rootRoute) {
          foundRoute = {
            ...rootRoute,
            isActive: false,
            children: rootRoute?.children.map((child) => ({
              ...child,
              isActive: child.route === targetRoute,
            })),
          };
          break;
        } else {
          foundRoute = {
            ...route,
            isActive,
            children: route.children || [],
          };
        }
      }
    }
  };

  traverse(extractedRoutes);

  return foundRoute;
};

export default findCurrentPageRouteTree;
