import React, { useEffect, useState } from "react";
import Head from "next/head";
import { useIntl } from "react-intl";
import { useRouter } from "next/router";
import removeHTMLTags from "modules/layout/utils/removeHTMLTags";

const generateAssetUrl = (val, origin) => {
  if (!val || !URL || !origin) return null;
  try {
    const url = new URL(val);
    return url.href;
  } catch (e) {
    const url = new URL(origin + val);
    return url.href;
  }
};

export type IPageSeo = {
  description: string;
  title: string;
  robots?: string;
  image?: any;
  keywords?: string;
  noindex?: boolean;
};
const LocalizedHead = ({
  fallbackTitle,
  seo,
  routes = null,
  disableAlternatives = false,
}: {
  fallbackTitle: string;
  seo?: IPageSeo;
  routes?: Record<string, string>;
  disableAlternatives?: boolean;
}) => {
  const { locale } = useIntl();
  const { locales: supportedLocales, asPath } = useRouter();
  const [location, setLocation] = useState(null);

  useEffect(() => {
    setLocation(document.location);
  }, []);
  const { description, title, robots, image, keywords } = seo || {};

  let links = [];

  let normalizedImage = image?.path || image;

  if (routes) {
    links = Object.entries(routes || {})
      .filter(([cmsLocale]) => {
        const normalizedLocale = cmsLocale === "default" ? "de" : cmsLocale;
        return normalizedLocale !== locale;
      })
      .map(([cmsLocale, cmsPath]) => {
        const normalizedLocale = cmsLocale === "default" ? "de" : cmsLocale;
        const normalizedPath = cmsPath === "/home" ? "" : cmsPath;

        return {
          href: `/${normalizedLocale}${normalizedPath}`,
          locale: normalizedLocale,
        };
      });
  } else {
    links = supportedLocales
      .filter((l) => l !== locale && l !== "dummy")
      .map((l) => {
        return {
          href: `/${l}${asPath}`,
          locale: l,
        };
      });
  }

  return (
    <Head>
      <title>
        {removeHTMLTags(title)?.substr(0, 60) ||
          removeHTMLTags(fallbackTitle)?.substr(0, 60)}
      </title>
      <meta
        property="og:title"
        content={removeHTMLTags(title) || removeHTMLTags(fallbackTitle)}
      />
      {keywords ? (
        <meta name="keywords" content={keywords} />
      ) : (
        <meta
          name="keywords"
          content="Publicare, Publicare AG,  Commerce, e-commerce, medical, medicine, marketplace"
        />
      )}
      {description && (
        <>
          <meta
            name="description"
            content={
              removeHTMLTags(description) ||
              removeHTMLTags(title) ||
              removeHTMLTags(fallbackTitle)
            }
          />
          <meta
            property="og:description"
            content={
              removeHTMLTags(description) ||
              removeHTMLTags(title) ||
              removeHTMLTags(fallbackTitle)
            }
          />
        </>
      )}
      {robots && <meta name="robots" content={robots} />}
      {seo?.noindex && <meta name="robots" content="noindex" />}

      <meta property="og:image" content={normalizedImage} />

      {title && (
        <meta property="og:image:alt" content={removeHTMLTags(title)} />
      )}
      <meta property="og:locale" content={locale} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:site_name" content="Publicare AG" />
      <meta name="twitter:title" content={removeHTMLTags(title)} />
      {title && (
        <meta
          name="twitter:description"
          content={removeHTMLTags(description) || removeHTMLTags(title)}
        />
      )}
      {normalizedImage && (
        <meta name="twitter:image" content={normalizedImage} />
      )}
      <meta property="og:url" content={location?.origin} />
      <meta property="og:type" content="website" />

      {description && title && (
        <meta
          name="description"
          content={removeHTMLTags(description) || removeHTMLTags(title)}
        />
      )}

      {!disableAlternatives &&
        links.map(({ locale: linkLocale, href }) => (
          <link
            key={linkLocale}
            rel="alternate"
            hrefLang={linkLocale}
            href={href}
          />
        ))}
    </Head>
  );
};

export default LocalizedHead;
